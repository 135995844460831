import React, { useEffect } from "react"

import anime from "animejs"

import styles from "./SpacemanSVG.module.css"

export default function SpacemanSVG() {

  useEffect(() => {
    /*
    var tween = KUTE.to(
      "#bg-2",
      { path: "#bg-copy-2" },
      { duration: 5500, loop: true, yoyo: true, repeat: 10, morphIndex: 127 }
    ).start()
    var tween2 = KUTE.to(
      "#bg-1",
      { path: "#bg-copy-1" },
      { duration: 5500, loop: true, yoyo: true, repeat: 10, morphIndex: 127 }
    ).start()*/

    // Create a timeline with default parameters
    var tl = anime.timeline({
      easing: "linear",
      loop: true,
      duration: 5000,
    })

    // Add children
    tl.add({
      targets: "#rocketman",
      translateX: 10,
      translateY: 10,
    })
      .add({
        targets: "#rocketman",
        translateX: -10,
        translateY: -10,
      })
      .add({
        targets: "#rocketman",
        translateX: 0,
        translateY: 0,
      })
    anime({
      targets: ".cls-4",
      opacity: 1,
      duration: 1500,
    })

  
 
  }, [])


  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 523.82 607.78">
      <g class="cls-1">
        <g id="Ebene_1" data-name="Ebene 1">
          <path
            id="bg-1"
            className={styles.cls2}
            d="M287,274.18c34.87-79.77,117.28-42.42,182.32-50.79s180.67-92.07,246.2-56.73,94.64,125.2,70.69,197.39c-23.07,69.52-59.64,110.15-16.2,183.1s2.21,147-53,165.35-103.61-45.26-146.46-37.3S428.32,750,380.37,748.83c-45.12-1.13-80.17-20.37-85.32-81.49s32.39-106.17,32.39-172S238.35,385.33,287,274.18Z"
            transform="translate(-272.34 -158)"
          />
          <path
            id="bg-2"
            className={styles.cls3}
            d="M441.17,200.89c61.3-2.29,92.44,15.42,140,15.56s159.5-1.22,173.24,50-40.25,94-42.21,160,84.9,85.91,83.94,132.89c-1,48.17-52.69,101.74-140.82,106.89s-111.15,39.88-155.66,56.7c-54.11,20.44-194.85-2.58-147.51-88.92,31.93-58.23,35.7-107.06,35.57-151.7s-113.36-52.93-115.12-120.9C270.41,276.21,362.48,203.84,441.17,200.89Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M666.36,631.76a112.48,112.48,0,0,0,11.84-.87,112.48,112.48,0,0,0-11.84-.87,1.66,1.66,0,0,1-1.62-1.62,115.5,115.5,0,0,0-.87-11.84A112.16,112.16,0,0,0,663,628.4a1.67,1.67,0,0,1-1.62,1.62,112.53,112.53,0,0,0-11.85.87,112.53,112.53,0,0,0,11.85.87,1.66,1.66,0,0,1,1.62,1.62,112.16,112.16,0,0,0,.87,11.84,115.5,115.5,0,0,0,.87-11.84A1.65,1.65,0,0,1,666.36,631.76Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M750.51,512.2a112.16,112.16,0,0,0,11.84-.87,115.5,115.5,0,0,0-11.84-.87,1.65,1.65,0,0,1-1.62-1.62A112.48,112.48,0,0,0,748,497a112.48,112.48,0,0,0-.87,11.84,1.66,1.66,0,0,1-1.62,1.62,115.5,115.5,0,0,0-11.84.87,112.16,112.16,0,0,0,11.84.87,1.67,1.67,0,0,1,1.62,1.62,112.53,112.53,0,0,0,.87,11.85,112.53,112.53,0,0,0,.87-11.85A1.66,1.66,0,0,1,750.51,512.2Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M325.79,258.05a115.5,115.5,0,0,0,11.84-.87,112.16,112.16,0,0,0-11.84-.87,1.66,1.66,0,0,1-1.62-1.62,112.53,112.53,0,0,0-.87-11.85,112.53,112.53,0,0,0-.87,11.85,1.66,1.66,0,0,1-1.62,1.62,112.16,112.16,0,0,0-11.84.87,115.5,115.5,0,0,0,11.84.87,1.65,1.65,0,0,1,1.62,1.62,112.48,112.48,0,0,0,.87,11.84,112.48,112.48,0,0,0,.87-11.84A1.65,1.65,0,0,1,325.79,258.05Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M352.78,678.54a115.88,115.88,0,0,0,11.85-.87,112.53,112.53,0,0,0-11.85-.87,1.67,1.67,0,0,1-1.62-1.62,112.53,112.53,0,0,0-.87-11.85,115.88,115.88,0,0,0-.87,11.85,1.66,1.66,0,0,1-1.62,1.62,112.16,112.16,0,0,0-11.84.87,115.5,115.5,0,0,0,11.84.87,1.65,1.65,0,0,1,1.62,1.61,115.88,115.88,0,0,0,.87,11.85,112.53,112.53,0,0,0,.87-11.85A1.66,1.66,0,0,1,352.78,678.54Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M736.17,314.66a115.88,115.88,0,0,0,11.85-.87,112.53,112.53,0,0,0-11.85-.87,1.66,1.66,0,0,1-1.61-1.62,115.88,115.88,0,0,0-.87-11.85,112.53,112.53,0,0,0-.87,11.85,1.67,1.67,0,0,1-1.62,1.62,112.53,112.53,0,0,0-11.85.87,115.88,115.88,0,0,0,11.85.87,1.66,1.66,0,0,1,1.62,1.62,112.48,112.48,0,0,0,.87,11.84,115.83,115.83,0,0,0,.87-11.84A1.65,1.65,0,0,1,736.17,314.66Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M311,410a112.53,112.53,0,0,0,11.85-.87,115.88,115.88,0,0,0-11.85-.87,1.66,1.66,0,0,1-1.62-1.62,112.48,112.48,0,0,0-.87-11.84,115.83,115.83,0,0,0-.87,11.84,1.65,1.65,0,0,1-1.62,1.62,115.83,115.83,0,0,0-11.84.87,112.48,112.48,0,0,0,11.84.87,1.66,1.66,0,0,1,1.62,1.62,115.88,115.88,0,0,0,.87,11.85,112.53,112.53,0,0,0,.87-11.85A1.67,1.67,0,0,1,311,410Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M522.11,206.79a112.53,112.53,0,0,0,11.85-.87,112.53,112.53,0,0,0-11.85-.87,1.66,1.66,0,0,1-1.61-1.62,115.5,115.5,0,0,0-.87-11.84,112.16,112.16,0,0,0-.87,11.84,1.67,1.67,0,0,1-1.62,1.62,112.53,112.53,0,0,0-11.85.87,112.53,112.53,0,0,0,11.85.87,1.66,1.66,0,0,1,1.62,1.62,112.16,112.16,0,0,0,.87,11.84,115.5,115.5,0,0,0,.87-11.84A1.65,1.65,0,0,1,522.11,206.79Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M694.18,262.13a37.61,37.61,0,0,0,3.44,2.08,38.7,38.7,0,0,0-3.09-2.56.56.56,0,0,1-.13-.77,38.83,38.83,0,0,0,2.08-3.44,40.15,40.15,0,0,0-2.56,3.1.57.57,0,0,1-.77.13c-1.89-1.27-3.44-2.08-3.44-2.08a37.9,37.9,0,0,0,3.1,2.56.57.57,0,0,1,.13.77,36.09,36.09,0,0,0-2.08,3.44,40.15,40.15,0,0,0,2.56-3.1A.56.56,0,0,1,694.18,262.13Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M624.86,189.27a36.89,36.89,0,0,0,3.45,2.08,40.15,40.15,0,0,0-3.1-2.56.56.56,0,0,1-.13-.76c1.27-1.89,2.08-3.44,2.08-3.44a38.7,38.7,0,0,0-2.56,3.09.57.57,0,0,1-.77.13c-1.89-1.27-3.44-2.08-3.44-2.08a37.9,37.9,0,0,0,3.1,2.56.57.57,0,0,1,.13.77,37.61,37.61,0,0,0-2.08,3.44,37.9,37.9,0,0,0,2.56-3.1A.56.56,0,0,1,624.86,189.27Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M313,351a37.68,37.68,0,0,0,3.44,2.09,38.78,38.78,0,0,0-3.1-2.57.55.55,0,0,1-.13-.76c1.27-1.89,2.08-3.44,2.08-3.44a37.9,37.9,0,0,0-2.56,3.1.58.58,0,0,1-.77.13,37.68,37.68,0,0,0-3.44-2.09,36.68,36.68,0,0,0,3.1,2.57.55.55,0,0,1,.13.76,37.61,37.61,0,0,0-2.08,3.44,37.9,37.9,0,0,0,2.56-3.1A.58.58,0,0,1,313,351Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M381.31,399.45a37.61,37.61,0,0,0,3.44,2.08,37.9,37.9,0,0,0-3.1-2.56.58.58,0,0,1-.13-.77,37.68,37.68,0,0,0,2.09-3.44,38.78,38.78,0,0,0-2.57,3.1.55.55,0,0,1-.76.13c-1.89-1.27-3.44-2.08-3.44-2.08a37.9,37.9,0,0,0,3.1,2.56.58.58,0,0,1,.13.77,37.68,37.68,0,0,0-2.09,3.44,36.68,36.68,0,0,0,2.57-3.1A.55.55,0,0,1,381.31,399.45Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M339.26,606.27a38.55,38.55,0,0,0,3.45,2.09,38.78,38.78,0,0,0-3.1-2.57.55.55,0,0,1-.13-.76c1.27-1.89,2.08-3.44,2.08-3.44a37.9,37.9,0,0,0-2.56,3.1.58.58,0,0,1-.77.13,39.35,39.35,0,0,0-3.44-2.09,36.68,36.68,0,0,0,3.1,2.57.55.55,0,0,1,.13.76,37.61,37.61,0,0,0-2.08,3.44,37.9,37.9,0,0,0,2.56-3.1A.57.57,0,0,1,339.26,606.27Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M682.67,556.17a37.61,37.61,0,0,0,3.44,2.08,38.7,38.7,0,0,0-3.09-2.56.57.57,0,0,1-.13-.77,38.83,38.83,0,0,0,2.08-3.44,40.15,40.15,0,0,0-2.56,3.1.57.57,0,0,1-.77.13c-1.89-1.27-3.44-2.08-3.44-2.08a37.9,37.9,0,0,0,3.1,2.56.57.57,0,0,1,.13.77,37.61,37.61,0,0,0-2.08,3.44,37.9,37.9,0,0,0,2.56-3.1A.56.56,0,0,1,682.67,556.17Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M734.2,476a39.35,39.35,0,0,0,3.44,2.09,36.68,36.68,0,0,0-3.1-2.57.56.56,0,0,1-.13-.76,39.35,39.35,0,0,0,2.09-3.44,39.12,39.12,0,0,0-2.57,3.1.56.56,0,0,1-.76.13,39.35,39.35,0,0,0-3.44-2.09,36.68,36.68,0,0,0,3.1,2.57.55.55,0,0,1,.13.76,37.61,37.61,0,0,0-2.08,3.44,37.9,37.9,0,0,0,2.56-3.1A.56.56,0,0,1,734.2,476Z"
            transform="translate(-272.34 -158)"
          />
          <path
            className={styles.cls4}
            d="M522.61,466.48a115.5,115.5,0,0,0,11.84-.87,112.16,112.16,0,0,0-11.84-.87,1.66,1.66,0,0,1-1.62-1.62,115.88,115.88,0,0,0-.87-11.85,112.53,112.53,0,0,0-.87,11.85,1.67,1.67,0,0,1-1.62,1.62,112.53,112.53,0,0,0-11.85.87,115.88,115.88,0,0,0,11.85.87,1.66,1.66,0,0,1,1.62,1.62,112.16,112.16,0,0,0,.87,11.84A115.5,115.5,0,0,0,521,468.1,1.65,1.65,0,0,1,522.61,466.48Z"
            transform="translate(-272.34 -158)"
          />
          <g id="rocketman">
            <path
              className={styles.cls5}
              d="M357.32,463.49s-6.32,10,4.35,10.48"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M361.88,474.31s-6.32,10,4.35,10.48"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M375.56,506.79s-6.32,9.95,4.35,10.47"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M371,496s-6.32,10,4.35,10.48"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M366.44,485.14s-6.32,9.95,4.35,10.48"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M380.12,517.61s-6.32,9.95,4.35,10.48"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M403.47,566.9s.33,11.44,9.07,5.84"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M412.85,572.89s.33,11.45,9.07,5.85"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M441.06,590.87s.33,11.44,9.06,5.84"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M431.68,584.87s.33,11.45,9.06,5.85"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M422.26,578.86s.33,11.44,9.07,5.83"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M450.51,596.86s.33,11.45,9.06,5.84"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M361.84,765.28c-.13-60.38,48.89-71.67,96.3-82.6,34-7.84,69.15-15.94,85-42.36,17.35-28.9,20.56-60.75,9.52-94.68a148.31,148.31,0,0,0-22-42.71l3.46-2.78a150.13,150.13,0,0,1,22.76,44c7.91,24.24,12.62,61-9.9,98.48C530.13,670.65,494,679,459.14,687c-47.82,11-93,21.43-92.86,78.26Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M585.75,542.16s44,40.46,77-6.59c0,0,19.6-36.7-35.11-88.94l-12.55,12.71s38.5,32,32.08,57.41c0,0-6.42,53.64-51.33,6.58C595.84,523.33,583,536.51,585.75,542.16Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M552.76,534.17s23.84,12.69,33,3.28,55.33-121.76,55.33-121.76,8.83-34.46-24.17-40.11S552.76,534.17,552.76,534.17Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M490.42,452.65s-65.08-7.73-83.42,27c0,0-11-48.29-49.5-17.39L385,527s26.59-4.82,44-19.31c0,0,32.09-9.65,46.75,1.93,0,0-20.16,11.6-22.91,46.36,0,0-31.17-35.76-49.5,9.66L462,604.27s20.17-20.28,23.84-38.63c0,0,20.16-32.84,58.66-29.94,0,0,13.75-1.93,15.59-3.87s20.16-38.58,26.58-55S607.75,442,607.75,442s-14.66-13.52-7.33-33.8,11-16.42,11-16.42-32.08,25.11-80.67-8.69l-18.33,23.18S492.25,439.12,490.42,452.65Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M625.63,398.64a33,33,0,0,0-8.4-.81,20.83,20.83,0,0,0-4.1.11,16.88,16.88,0,0,0-3.94.94,14.7,14.7,0,0,0-6.41,4.78,21.1,21.1,0,0,0-3.5,7.53,39.07,39.07,0,0,0-1.21,8.47c0,.72-.08,1.43-.09,2.16V424c0,1.44.12,2.88.21,4.32s.27,2.88.43,4.32l.27,2.16c.09.72.27,1.43.34,2.15.14,2.92.5,5.82.7,8.76-.91-2.82-1.83-5.59-2.51-8.47-.26-1.45-.46-2.91-.61-4.37s-.37-2.93-.44-4.41-.23-2.95-.22-4.43v-2.23c0-.73.06-1.48.09-2.22a40.83,40.83,0,0,1,1.28-8.87,22.45,22.45,0,0,1,3.82-8.19,16.47,16.47,0,0,1,7.23-5.39,21.35,21.35,0,0,1,8.78-1.18A26.5,26.5,0,0,1,625.63,398.64Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls7}
              d="M484.46,386.4c-.71,1.19-1.49,2.29-2.28,3.4l-2.93,2.66-5.86,5.31c-2,1.76-3.94,3.5-6,5.22-1,.86-2,1.71-3.05,2.55a19.84,19.84,0,0,1-3.75,1.57c.47-.5.91-1,1.34-1.52s.86-1,1.28-1.53c1-.82,2-1.66,3-2.51,2-1.71,4-3.45,5.93-5.2l5.84-5.29c1-.94,2-1.78,2.91-2.68C482.09,387.7,483.22,387,484.46,386.4Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M503.71,419.34c1.51-3.28,3.12-6.53,4.77-9.75l2.51-4.81c.43-.8.84-1.61,1.29-2.39s.92-1.54,1.43-2.3c1.91-3.07,3.87-6.11,6-9.07,1.06-1.47,2.14-2.93,3.33-4.33a21.12,21.12,0,0,1,4-3.91l.73-.5.52.79a50.17,50.17,0,0,0,3.94,5.1,58.77,58.77,0,0,0,4.49,4.71A57.27,57.27,0,0,0,547,400.65a46,46,0,0,0,24.23,6.66,52.58,52.58,0,0,0,12.58-1.88c2-.68,4.09-1.22,6-2.1,1-.42,2-.78,3-1.24l2.89-1.43c3.81-2,7.42-4.34,11.13-6.59,1.78-1.24,3.57-2.45,5.34-3.76l5.19-4-4.75,4.56c-1.65,1.44-3.34,2.83-5,4.28L605,397.23a32.71,32.71,0,0,1-2.69,1.88l-2.86,1.65-1.42.84c-.49.26-1,.49-1.48.74l-2.95,1.47c-1,.48-2,.86-3,1.28a57.56,57.56,0,0,1-6.24,2.17,54.58,54.58,0,0,1-13,1.88,47.87,47.87,0,0,1-25.22-6.95,60.84,60.84,0,0,1-15.17-12.77,52.08,52.08,0,0,1-4.11-5.33l1.26.29a19.53,19.53,0,0,0-3.67,3.54c-1.12,1.34-2.19,2.77-3.22,4.21-2.08,2.91-4,5.92-5.91,9-.46.77-.91,1.55-1.39,2.3l-1.47,2.3-2.94,4.52Q506.56,414.74,503.71,419.34Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M689.8,398.64s-29.34,38.58-59.59,6.59c0,0-3.66-14.12-20.16-14.12,0,0-28.42,30.11-1.84,50.82,0,0,11.92,11.29,22.92,12.23s33.92-2.82,41.25-7.53,23.83-2.82,23.83-2.82,23.84,2.82,18.34-24.47S689.8,398.64,689.8,398.64Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M530.75,383.11s-8.25-4.71-20.16,12.23c0,0-29.34,4.71-38.5-32.94,0,0,5.19-31.22-1.78-40.23a3.11,3.11,0,0,0-5.08.3c-1.64,2.7-3.38,8.23-2.31,19.23,0,0-13.18-21.36-17.42-17.88-4.61,3.78,7.34,24.47,7.34,24.47s-12.84-3.77-14.67,5.64c0,0-9.17,2.83-1.83,19.77s32.54,58.35,63,56"
              transform="translate(-272.34 -158)"
            />
            <path
              d="M589.56,416.49a.52.52,0,0,0-.39-.5,1,1,0,0,0-.34-.05,4.69,4.69,0,0,0-1.18.13,12.25,12.25,0,0,0-3.28,1.18.29.29,0,0,1-.37,0,4.63,4.63,0,0,0-5.87.1,4.57,4.57,0,0,0-1.68,3.92.28.28,0,0,1-.14.29,24.94,24.94,0,0,0-2.19,1.5,4.32,4.32,0,0,0-.94.92.84.84,0,0,0-.16.75,1,1,0,0,0,.93.73,2.56,2.56,0,0,0,.78-.09,12.13,12.13,0,0,0,2.67-1l.25-.14c.05,0,.09,0,.13,0l.08.08a4.48,4.48,0,0,0,3.64,1.3,4.56,4.56,0,0,0,4-3.06,4.41,4.41,0,0,0,.17-2.64c-.05-.2,0-.16.11-.25l1.61-.92a9.21,9.21,0,0,0,1.49-1.07,2.09,2.09,0,0,0,.5-.61A1.11,1.11,0,0,0,589.56,416.49Zm-11.31,1.14a4.21,4.21,0,0,1,3.14-1.07,4.3,4.3,0,0,1,3.61,2.32s0,0,0,.08l-.31.18c-2.36,1.38-4.77,2.65-7.22,3.86l-.06,0c-.14.07-.14.07-.22-.08A4.39,4.39,0,0,1,578.25,417.63Zm6.58.41a7.6,7.6,0,0,1,.92-.43,11.89,11.89,0,0,1,1.54-.47.77.77,0,0,1,.3,0s.1,0,.11,0,0,.08-.05.11l-.19.18a4.19,4.19,0,0,1-.6.44c-.47.31-1,.58-1.44.87-.08,0-.12,0-.16-.05A4.38,4.38,0,0,0,584.83,418ZM577,423.21a13.53,13.53,0,0,1-2.84,1,13.28,13.28,0,0,1,2.48-1.84A6,6,0,0,0,577,423.21Zm6.48,1.41A4.36,4.36,0,0,1,578,424c2.44-1.4,4.86-2.8,7.35-4.11A4.32,4.32,0,0,1,583.5,424.62Zm5.74-8a1.15,1.15,0,0,1-.27.54,3.68,3.68,0,0,1-.4.39,12.86,12.86,0,0,1-1.37.95c-1.35.8-2.75,1.52-4.13,2.27-1.65.91-3.27,1.86-4.9,2.79a14.45,14.45,0,0,1-2.95,1.32,5,5,0,0,1-1,.24.82.82,0,0,1-.7-.2.57.57,0,0,1-.07-.77,3.06,3.06,0,0,1,.68-.71c.76-.59,1.54-1.14,2.34-1.67l.06,0c.09.35.09.35-.19.53-.45.29-.92.54-1.35.87a7.69,7.69,0,0,0-.83.68.89.89,0,0,0-.22.29.28.28,0,0,0,.2.42.64.64,0,0,0,.39,0c.17,0,.33-.1.49-.15a29.41,29.41,0,0,0,4.18-1.86c1.88-1,3.74-1.94,5.56-3,.73-.44,1.5-.83,2.22-1.3a5,5,0,0,0,.83-.63,1.16,1.16,0,0,0,.2-.24.3.3,0,0,0-.16-.49.87.87,0,0,0-.22,0,1.61,1.61,0,0,0-.49.07,10.74,10.74,0,0,0-2.34.85c-.15.08-.15.08-.26,0a.76.76,0,0,0-.09-.09,6,6,0,0,1,.61-.32,12.34,12.34,0,0,1,2.89-.93,4,4,0,0,1,.85-.08.82.82,0,0,1,.22,0C589.21,416.31,589.28,416.41,589.24,416.63Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls8}
              d="M577.45,423l.06,0c2.45-1.21,4.86-2.48,7.22-3.86L585,419s0-.06,0-.08a4.3,4.3,0,0,0-3.61-2.32,4.21,4.21,0,0,0-3.14,1.07,4.39,4.39,0,0,0-1,5.31C577.31,423.09,577.31,423.09,577.45,423Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls8}
              d="M578,424a4.36,4.36,0,0,0,7.35-4.11C582.88,421.21,580.46,422.61,578,424Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls4}
              d="M589,416.27a.82.82,0,0,0-.22,0,4,4,0,0,0-.85.08,12.34,12.34,0,0,0-2.89.93,6,6,0,0,0-.61.32.76.76,0,0,1,.09.09c.11.12.11.12.26,0a10.74,10.74,0,0,1,2.34-.85,1.61,1.61,0,0,1,.49-.07.87.87,0,0,1,.22,0,.3.3,0,0,1,.16.49,1.16,1.16,0,0,1-.2.24,5,5,0,0,1-.83.63c-.72.47-1.49.86-2.22,1.3-1.82,1.07-3.68,2.05-5.56,3a29.41,29.41,0,0,1-4.18,1.86c-.16.05-.32.11-.49.15a.64.64,0,0,1-.39,0,.28.28,0,0,1-.2-.42.89.89,0,0,1,.22-.29,7.69,7.69,0,0,1,.83-.68c.43-.33.9-.58,1.35-.87.28-.18.28-.18.19-.53l-.06,0c-.8.53-1.58,1.08-2.34,1.67a3.06,3.06,0,0,0-.68.71.57.57,0,0,0,.07.77.82.82,0,0,0,.7.2,5,5,0,0,0,1-.24,14.45,14.45,0,0,0,2.95-1.32c1.63-.93,3.25-1.88,4.9-2.79,1.38-.75,2.78-1.47,4.13-2.27a12.86,12.86,0,0,0,1.37-.95,3.68,3.68,0,0,0,.4-.39,1.15,1.15,0,0,0,.27-.54C589.28,416.41,589.21,416.31,589,416.27Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M560.87,478.58C543.31,463.5,526,454,503.8,447.67q7.76-14.58,15.6-29.11a154.43,154.43,0,0,1,62.87,24.66C575,454.92,567.81,466.67,560.87,478.58Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M560.24,475.8C543.54,462,527,453,506.27,447q7.13-13.38,14.36-26.7a154.34,154.34,0,0,1,59,23.47Q569.68,459.63,560.24,475.8Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M529.13,429.72a4.09,4.09,0,0,1-4.4,1.76c-1.67-.41-2.43-1.81-1.67-3.19a4.29,4.29,0,0,1,4.45-1.87A2.19,2.19,0,0,1,529.13,429.72Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M521.15,444.27a3.59,3.59,0,0,1-4.12,1.36c-1.6-.49-2.38-1.84-1.71-3.08a3.75,3.75,0,0,1,4.17-1.47C521.11,441.55,521.84,443,521.15,444.27Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M525,437.19a3.82,3.82,0,0,1-4.26,1.56c-1.63-.45-2.4-1.82-1.69-3.13a4,4,0,0,1,4.31-1.67A2.2,2.2,0,0,1,525,437.19Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M547.38,455.39a103.06,103.06,0,0,0-20.28-9.75c2.85-5.17,4.35-7.89,7.54-13.58a107.71,107.71,0,0,1,21.14,8.82C552.23,446.93,550.56,449.84,547.38,455.39Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls9}
              d="M571.28,448.1a109.53,109.53,0,0,0-10.64-6.69c.63-1.07,1-1.61,1.6-2.69A110,110,0,0,1,573,445.33C572.28,446.44,572,447,571.28,448.1Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls9}
              d="M568,453.53a108.56,108.56,0,0,0-10.45-6.86c.6-1,.91-1.56,1.53-2.61a107.84,107.84,0,0,1,10.55,6.77C569,451.91,568.64,452.45,568,453.53Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls9}
              d="M564.83,458.81a107.89,107.89,0,0,0-10.28-7c.58-1,.88-1.52,1.48-2.54a108.28,108.28,0,0,1,10.37,6.94C565.76,457.24,565.45,457.77,564.83,458.81Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls9}
              d="M561.79,464a109.54,109.54,0,0,0-10.12-7.2l1.42-2.46a106.5,106.5,0,0,1,10.2,7.11Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls9}
              d="M558.86,469a108.06,108.06,0,0,0-10-7.38c.54-.94.82-1.42,1.37-2.39a106.31,106.31,0,0,1,10,7.29C559.72,467.47,559.43,468,558.86,469Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls4}
              d="M767.05,351.81c-24.37-11.7-48.75,1.88-73.12-11.29l1.59,17h0l1.6,17h0q.78,8.55,1.58,17c21.2-11.45,42.38.36,63.57-9.79l1.59-10h0l1.59-10h0Zm-27.81,8c-5.1,0-10.2.1-15.3.09C729,359.92,734.14,359.82,739.24,359.82Zm-34.32,13.86c19.28-1.64,38.56.51,57.85-1.77C743.48,374.23,724.2,372.05,704.92,373.68Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M705.57,451.48h0c1.28-.17,2.18-1.67,2-3.35L695.71,340.5c-.19-1.68-1.39-2.92-2.67-2.76h0c-1.28.17-2.18,1.68-2,3.35L702.9,448.73C703.09,450.4,704.3,451.64,705.57,451.48Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls10}
              d="M420.69,464.82s20,23,18,40.65"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls10}
              d="M490.42,452.65s-13.89,12.67-10.09,37.73"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls10}
              d="M570,513.21s-19.7-36.54-50.87-4.81"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls10}
              d="M457.7,533.76s27.74,5.55,31.22,27.62"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M732.56,367.9c-4.85,1.16-7.27,2-12.13,1.77.15,5,.22,7.48.37,12.44a15.86,15.86,0,0,1-4.66-.22q-.43-15.24-.86-30.46a17.08,17.08,0,0,0,4.67,1.44c.14,4.81.21,7.21.35,12,4.85.39,7.27-.31,12.13-1.3-.14-4.32-.21-6.49-.35-10.83,1.85-.22,2.77-.34,4.62-.49.34,10.25.51,15.34.85,25.46-1.85.52-2.77.83-4.62,1.44C732.78,374.66,732.71,372.41,732.56,367.9Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls5}
              d="M744.63,352.37a17.28,17.28,0,0,1,4.66,1.29c.34,9.35.51,14,.85,23.21a14.8,14.8,0,0,0-4.66-.51C745.14,366.83,745,362,744.63,352.37Z"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M438.17,353.93"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M467.41,364.56s-6.08-7-5.13-13.41,10.26,6.39,10.26,6.39,4.7,3,5.49,5.43-1.52,11-1.52,11l-1,.52"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M438.17,353.93"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls10}
              d="M437.76,354.58s13,8.82,8.62,11.2S438.3,364,438.3,364l-4.81-4.25"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls10}
              d="M443.8,348s15.11,10.25,10,13-9.39-2.1-9.39-2.1L438.84,354"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M711.72,410.93s-18.11,7.41-17.75,1.64,6.76-6.85,6.76-6.85l7.16-2.12"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M714.37,418.69s-16.71,7.37-16.35,1.6,6.76-6.85,6.76-6.85l7.16-2.12"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M714.88,427.57s-15.93,7-15.57,1.26,6.76-6.84,6.76-6.84l8.3-2.46"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls6}
              d="M713.5,434.5s-13.43,6.64-13.14,1.82S706,430.6,706,430.6l6-1.77"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls10}
              d="M650.81,417.94s-12.94,14.82-3.35,35.45"
              transform="translate(-272.34 -158)"
            />
            <path
              className={styles.cls10}
              d="M472.54,421s-8.2-19.81,15-32.25"
              transform="translate(-272.34 -158)"
            />
            <g id="Ebene_1-2" data-name="Ebene 1">
              <path
                className={styles.cls6}
                d="M527.8,385.27c6.48,6.48,16.91,11.23,31.36,16.16,17,5.8,29.38,8.44,39.28,5.47L528.57,383Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M600.36,283.64s-61.22-21.92-78.84,48.59c0,0-13.73,40,12,53.32s39.12,16,39.12,16,42.4,18.37,57-16.89C629.62,384.66,676.81,308.82,600.36,283.64Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M533.07,328.43c13.13-38.37,45.49-48.68,77.35-37.78A74,74,0,0,1,629,300c-7.17-7.43-16.56-13.14-27.44-16.87-31.85-10.89-64.21-.6-77.35,37.78-12.16,35.56-9.28,52.33,9.07,64.36C523.24,373.62,523.25,357.16,533.07,328.43Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M524.2,320.58c.26-.77.55-1.5.82-2.25l-8.23-2.82a1.83,1.83,0,0,0-2.31.84l-9.31,22.83a2,2,0,0,0,1.12,2.67l.15.06,11.19,3.82A145,145,0,0,1,524.2,320.58Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M519.84,323.61a2,2,0,1,1-1.23-2.5h0a2,2,0,0,1,1.24,2.5Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M517.47,336.22a3.81,3.81,0,1,1-2.37-4.84h0a3.8,3.8,0,0,1,2.37,4.84Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M516,335.73a2.3,2.3,0,1,1-1.43-2.92h0a2.29,2.29,0,0,1,1.43,2.92Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M649.43,362.51a1.84,1.84,0,0,0-1.32-2.09l-8.2-2.81c-.24.76-.46,1.52-.73,2.28A146.06,146.06,0,0,1,629,383.81l11.17,3.82a2,2,0,0,0,2.61-1.23.75.75,0,0,0,0-.16Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M640.74,365a2,2,0,1,0,3.74,1.29,2,2,0,0,0-1.23-2.51h0a2,2,0,0,0-2.51,1.23Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M634.89,376.38a3.82,3.82,0,1,0,4.85-2.37h0a3.81,3.81,0,0,0-4.84,2.37Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M636.32,376.87a2.31,2.31,0,1,0,2.93-1.43h0A2.32,2.32,0,0,0,636.32,376.87Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls11}
                d="M607.54,322.36c3.18-4.25,14,4.07,18.31,11.73s5.15,14.46,1.38,16.17c-5.12,2.32-5-6.46-10.58-13.27C613.35,333,604.19,326.83,607.54,322.36Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls6}
                d="M525.11,328.35s6-28.67,38.82-33.15,57.63,17.92,57.63,17.92,26.58,22.1,13.44,53.15c0,0-10.45,37-41.21,29.86,0,0-49-8.36-65.4-27.17C528.39,369,514.06,359.11,525.11,328.35Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls12}
                d="M526.79,329s5.8-27.81,37.67-32.16,55.92,17.39,55.92,17.39,25.79,21.44,13,51.57c0,0-10.15,35.93-40,29,0,0-47.51-8.12-63.45-26.37C530,368.39,516.07,358.83,526.79,329Z"
                transform="translate(-272.34 -158)"
              />
              <path
                className={styles.cls13}
                d="M608.83,323.3c2.76-3.68,12.16,3.53,15.9,10.18s4.46,12.56,1.21,14c-4.46,2-4.33-5.6-9.19-11.52C613.89,332.53,605.92,327.18,608.83,323.3Z"
                transform="translate(-272.34 -158)"
              />
              <path
                d="M576.05,380.59c-45-20.1-45.91-35.16-32.61-64.93a41.66,41.66,0,0,1,13.69-17.07c-13.92,3.67-24.89,13-29.49,27.82-9.55,30.75-8.36,44.49,37.73,61.44,25.55,9.4,40.61,9.58,50.54,3.31C606.36,391.84,593.79,388.52,576.05,380.59Z"
                transform="translate(-272.34 -158)"
              />
            </g>
          </g>
          <path
            className={styles.cls4}
            d="M635.57,255.48a123.06,123.06,0,0,0,12.83-.95,125,125,0,0,0-12.83-.94,1.79,1.79,0,0,1-1.75-1.75,122.74,122.74,0,0,0-1-12.83,124.62,124.62,0,0,0-.94,12.83,1.79,1.79,0,0,1-1.75,1.75,124.62,124.62,0,0,0-12.83.94,122.74,122.74,0,0,0,12.83.95,1.79,1.79,0,0,1,1.75,1.75,125,125,0,0,0,.94,12.83,123.06,123.06,0,0,0,1-12.83A1.79,1.79,0,0,1,635.57,255.48Z"
            transform="translate(-272.34 -158)"
          />
          <path
            id="bgCopy2"
         
            className={`${styles.cls2} ${styles.bgCopy2}`}
            d="M417,220c61,9,155.42-34.15,203-34s116.26,17.75,130,69-36,107.61-38,173.63,84.89,85.9,83.93,132.88C795,609.68,705.13,653.85,617,659s-73,49.45-117.53,66.1C452.36,742.71,345,671.38,343,605s44.66-75.87,44.53-120.51S274.17,431.56,272.4,363.58C270.19,278.39,330.34,207.21,417,220Z"
            transform="translate(-272.34 -158)"
          />
          <path
            id="bgCopy1"
            className={`${styles.cls8} ${styles.bgCopy1}`}
            d="M309,288c34.87-79.77,104-95,159.84-63.61C526,256.53,649.47,149.66,715,185s82,131.81,58,204c-23.07,69.53-46.91,86.21-3.47,159.16S767.22,680.59,712,699s-99.09-30.76-141.93-22.8S436,724,385,734c-44.3,8.69-100.85-1.89-106-63s48-108.82,48-174.65S260.4,399.16,309,288Z"
            transform="translate(-272.34 -158)"
          />
        </g>
      </g>
    </svg>
  )
}
