import React, { useRef, useEffect, useState } from "react"
import SEO from "../components/seo"
import SpacemanSVG from "../svg/SpacemanSVG"
import $ from "jquery"

import { TimelineMax, Power2 } from "gsap"
import AnimatedHeadline from "../components/AnimatedHeadline"
import styles from "../styles/_contact.module.scss"
import Footer from "../components/footer"
import ogImageKontakt from "../images/og-image/og-image-kontakt.png"
export default function Contact() {
  let form = useRef(null)
  let overlay = useRef(null)

  const [nameValue, setNameValue] = useState("")
  const [emailValue, setEmailValue] = useState("")
  const [projectValue, setProjectValue] = useState("")
  const [error, setError] = useState(false)

  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {}, [])

  // Animation after submit
  function animateAfterSuccess() {
    let tl = new TimelineMax()
    console.log(disabled)

    tl.to(`.${styles.cbOverlay}`, 0, {
      css: { visibility: "visible", display: "flex" },
    })
    tl.from(
      `.${styles.cbOverlay}`,
      0.15,
      { opacity: 0, scale: 0.7 },
      { opacity: 1, scale: 1 }
    )
  }

  function sendPostRequest(e) {
    $(`.cb-button`).css("cursor", "wait")
    e.preventDefault()
    setDisabled(true)

    fetch("http://wordpress.cuuno.com/wp-json/form/contact", {
      method: "POST",
      body: JSON.stringify({ nameValue, emailValue, projectValue }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        /*  this.setState({ isSubmitting: false }) */
        console.log(res)
        $(`.cb-button`).css("cursor", "pointer")
        animateAfterSuccess()
        setNameValue("")
        setEmailValue("")
        setProjectValue("")
        setLoading(false)
        return res
      })
      .then(data => {})
    /*  $(form).submit(function(e) {
      e.preventDefault()
      if (!disabled) {
        setDisabled(true)
        setLoading(true)
        $.ajax({
          url: "http://wordpress.cuuno.com/wp-json/form/contact",
          type: "POST",
          dataType: "json",
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify({ nameValue, emailValue, projectValue }),
          success: function(response) {
         
          },
          error: function(jqXHR, textStatus, errorMessage) {
            setError(true)
          },
        })
      }
    }) */
  }

  return (
    <>
      <SEO
        title="Kontakt. Sagen Sie Hallo zu Cuuno."
        description="Tretten Sie mit uns in
        Kontakt. Egal ob per Mail oder über das Kontaktformular. Lassen Sie uns
        über ihre Idee sprechen."
        image={ogImageKontakt}
        defaultTitle="Kontakt - Cuuno"
        titleTemplate="Kontakt"
      />

      <section className={`contact ${styles.cbContact}`}>
        <div className="cb-container">
          <div className="cb-herosection">
            <div className="cb-herosection-wrap">
              <div className={`contact ${styles.cbContactGrid}`}>
                <div className={`contact ${styles.cbContactGridCol}`}>
                  <div className={`contact ${styles.cbContactBody}`}>
                    <div className={`contact ${styles.cbContactHeader}`}>
                      <AnimatedHeadline
                        mobileText="Tretten Sie mit uns in Kontakt. Lassen Sie uns über ihre Idee sprechen."
                        desktopText={[
                          "Tretten Sie mit uns in",
                          "Kontakt. Lassen Sie uns ",
                          "über ihre Idee sprechen.",
                        ]}
                      />
                    </div>

                    <form onSubmit={sendPostRequest} ref={el => (form = el)}>
                      <input
                        className={`contact ${styles.cbContactFormGridItem} ${styles.cbContactFormInput}`}
                        type="text"
                        required
                        name="Name"
                        placeholder="Ihr Name"
                        value={nameValue}
                        onChange={event => {
                          setNameValue(event.target.value)
                        }}
                      />
                      <input
                        className={`contact ${styles.cbContactFormGridItem} ${styles.cbContactFormInput}`}
                        type="text"
                        name="Email"
                        placeholder="Ihre Email-Adresse"
                        value={emailValue}
                        onChange={event => {
                          setEmailValue(event.target.value)
                        }}
                        required
                      />
                      <textarea
                        className={`contact ${styles.cbContactFormTextarea}`}
                        type="text"
                        name="Your Project"
                        placeholder="Ihr Projekt"
                        value={projectValue}
                        onChange={event => {
                          setProjectValue(event.target.value)
                        }}
                        required
                      />

                      <button
                        disabled={disabled}
                        type="submit"
                        className={`contact ${styles.cbContactSubmit}`}
                      >
                        <div className="cb-button -color-bg-blue ">
                          <p>Senden</p>
                          <span>
                            <svg
                              className="-no-rotate"
                              enableBackground="new 0 0 24 24"
                              viewBox="0 0 24 24"
                            >
                              <path d="m8.75 17.612v4.638c0 .324.208.611.516.713.077.025.156.037.234.037.234 0 .46-.11.604-.306l2.713-3.692z" />
                              <path d="m23.685.139c-.23-.163-.532-.185-.782-.054l-22.5 11.75c-.266.139-.423.423-.401.722.023.3.222.556.505.653l6.255 2.138 13.321-11.39-10.308 12.419 10.483 3.583c.078.026.16.04.242.04.136 0 .271-.037.39-.109.19-.116.319-.311.352-.53l2.75-18.5c.041-.28-.077-.558-.307-.722z" />
                            </svg>
                          </span>
                        </div>
                      </button>
                    </form>
                    <div style={{ padding: "20px" }}>
                      {loading && <p>Loading...</p>}
                      {error && (
                        <p>
                          Es ist ein Fehler aufgetreten, bitte kontaktieren Sie
                          uns per Mail
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className={`contact ${styles.cbContactGridCol}`}>
                  <div className={`contact ${styles.cbContactSvg}`}>
                    <SpacemanSVG />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={`contact ${styles.cbLinkbox}`}>
        <div className={`contact ${styles.cbLinkboxWrap}`} data-aos="fade-up">
          <div className={`contact ${styles.cbLinkboxText} cb-text`}>
            <p>Kontaktieren Sie uns</p>
          </div>
          <a
            href="mailto:partnerships@cuuno.com"
            className={`contact ${styles.cbLinkboxLink} cb-header`}
          >
            <h1>partnerships@cuuno.com</h1>
          </a>
        </div>
      </section>

      <section className={`contact ${styles.cbLocation}`}>
        <div className={`contact ${styles.cbLocationWrap}`} data-aos="fade-up">
          <div className="cb-container">
            <div className={`contact ${styles.cbLocationLink} cb-header`}>
              <h1>Wir sind für Sie da.</h1>
            </div>
            <div className={`contact ${styles.cbLocationLink} cb-header`}>
              <h1>Sagen Sie Hallo.</h1>
            </div>
            <div className={`contact ${styles.cbLocationWrapGrid}`}>
              <div className={`contact ${styles.cbLocationWrapGridCol}`}>
                <div className={`contact ${styles.cbLocationHeader}`}>
                  <h1>Unsere Adresse</h1>
                </div>
                <div className={`contact ${styles.cbLocationText}`}>
                  <p>Hopfenweg 12 </p>
                  <p> 88326 Aulendorf</p>
                  <p> Deutschland</p>
                </div>
              </div>
              {/* <div className={`contact ${styles.cbLocationWrapGridCol}`}>
                <div className={`contact ${styles.cbLocationHeader}`}>
                  <h1>Payment info</h1>
                </div>
              </div> */}
              {/*     <div className={`contact ${styles.cbLocationWrapGridCol}`}>
                <div className={`contact ${styles.cbLocationHeader}`}>
                  <h1>Lesen Sie mehr über uns</h1>
                </div>
              </div> */}
              <div className={`contact ${styles.cbLocationWrapGridCol}`}>
                <div className={`contact ${styles.cbLocationHeader}`}>
                  <h1>Sonstiges</h1>
                </div>
                <a
                  aria-label="Mailto Cuuno"
                  href="mailto:partnerships@cuuno.com"
                >
                  <span>partnerships</span>@cuuno.com
                </a>

                <a
                  aria-label="Mailto Johannes Schwegler"
                  href="mailto:johannes.schwegler@cuuno.com"
                >
                  <span>johannes.schwegler</span>@cuuno.com
                </a>
                <a
                  aria-label="Mailto Oliver Hagel"
                  href="mailto:Oliver.hagel@cuuno.com"
                >
                  <span>Oliver.hagel</span>@cuuno.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div ref={el => (overlay = el)} className={`contact ${styles.cbOverlay}`}>
        <div className={`contact ${styles.cbOverlayWrap}`}>
          <div class="cb-header">
            <h1>Vielen Dank für ihre Nachricht.</h1>
          </div>
          <div className={`contact ${styles.cbOverlaySubheader}`}>
            <h1>Wir melden uns.</h1>
          </div>
          <div
            onClick={e => {
              setDisabled(false)
              $(overlay)
                .first()
                .fadeOut(150)
            }}
            class="cb-button -color-bg-blue"
          >
            <p>Zurück zur Webseite</p>
            <span>
              <svg version="1.1" viewBox="0 0 492 492">
                <path
                  d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
 c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
 L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
 c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
 c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
 C492,219.198,479.172,207.418,464.344,207.418z"
                ></path>
              </svg>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
